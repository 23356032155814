import { render, staticRenderFns } from "./UltimoDatoDisponibleTab.vue?vue&type=template&id=92f26d2a&scoped=true&"
import script from "./UltimoDatoDisponibleTab.vue?vue&type=script&lang=js&"
export * from "./UltimoDatoDisponibleTab.vue?vue&type=script&lang=js&"
import style0 from "./UltimoDatoDisponibleTab.vue?vue&type=style&index=0&id=92f26d2a&prod&scoped=true&lang=css&"
import style1 from "./UltimoDatoDisponibleTab.vue?vue&type=style&index=1&id=92f26d2a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f26d2a",
  null
  
)

export default component.exports